import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VideoDialogComponent } from '@dialogs/videoDialog/videoDialog';
import { TranslatePipe } from '@app/pipes/translate.pipe';
import { GenericButtonWithArrow } from '@components/shared/genericButtonWithArrow/genericButtonWithArrow';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { environment } from '../../../../environments/handyhand/environment.dk';
import { ExternalService } from '@app/services/http/external.service';

@Component({
  imports: [
    CommonModule,
    NgOptimizedImage,
    TranslatePipe,
    GenericButtonWithArrow,
  ],
  providers: [TranslatePipe, ExternalService],
  selector: 'app-how-it-works',
  templateUrl: './howItWorks.component.html',
  styleUrls: ['./howItWorks.component.scss'],
  encapsulation: ViewEncapsulation.Emulated, // or ViewEncapsulation.ShadowDom
})
export class HowItWorksComponent implements OnInit {
  @Input() isVerticalPage: boolean = false;
  @Input() textIsWhite: boolean = false;
  @Input() noButton?: boolean = false;
  @Input() left_align?: boolean = false;
  @Input() titleLabel?: string;
  task = `${environment.cloudFrontImgUrl}/private/compressed/lp/webp/how+to+1.webp`;

  public tasks = [
    {
      title: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.FURNITURE_ASSEMBLY.TITLE',
      ),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.FURNITURE_ASSEMBLY.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/furntiture_assembly-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.PAINTING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.PAINTING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/painter-min.png`,
    },
    {
      title: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.MOVING_ASSISTANCE.TITLE',
      ),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.MOVING_ASSISTANCE.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/mover-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.GARDENING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.GARDENING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/gardener-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.IT_SUPPORT.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.IT_SUPPORT.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/tech_support-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.ELECTRICIAN.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.ELECTRICIAN.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/electrician_lamp-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.CLEANING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.CLEANING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/cleaning-min.png`,
    },
    {
      title: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.MOVING_ASSISTANCE_2.TITLE',
      ),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.MOVING_ASSISTANCE_2.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/movers_home-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.ELECTRICIAN_2.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.ELECTRICIAN_2.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/lamp_hanging-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.CATERING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.CATERING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/catering-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.PLUMBING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.PLUMBING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/plumber-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.CARPENTRY.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.CARPENTRY.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/carpenter-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.DOG_WALKING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.DOG_WALKING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/dog_walker-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.PHOTOGRAPHY.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.PHOTOGRAPHY.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/photographer-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.WEB_DESIGN.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.WEB_DESIGN.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/design-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.TUTORING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.TUTORING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/tutor-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.WINDOW_CLEANING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.WINDOW_CLEANING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/window_cleaning-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.LAWN_MOWING.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.LAWN_MOWING.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/lawn_mowing-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.GRAPHIC_DESIGN.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.GRAPHIC_DESIGN.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/digital_design-min.png`,
    },
    {
      title: this.t.transform('PAGES.HOW_IT_WORKS.TASKS.HANDYMAN.TITLE'),
      description: this.t.transform(
        'PAGES.HOW_IT_WORKS.TASKS.HANDYMAN.DESCRIPTION',
      ),
      image: `${environment.cloudFrontImgUrl}/private/lp/handyman-min.png`,
    },
  ];

  public taskPairs: any[][] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private t: TranslatePipe,
    public externalService: ExternalService,
  ) {}

  ngOnInit() {
    // Split tasks into two large chunks instead of pairs
    const halfLength = Math.ceil(this.tasks.length / 2);
    this.taskPairs = [
      this.tasks.slice(0, halfLength),
      this.tasks.slice(halfLength),
    ];
  }

  public openVideoDialog() {
    this.dialog.open(VideoDialogComponent, {
      disableClose: true,
      panelClass: 'full-screen-modal-mobile',
      width: '800px',
      maxWidth: 'unset',
      data: {
        title: this.t.transform('PAGES.HOW_IT_WORKS.HEADLINE'),
        vidChoice: 'howItWorks',
      },
    });
  }
}
