import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/handyhand/environment.dk';
import { TranslatePipe } from '@app/pipes/translate.pipe';
import {
  CommonModule,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GenericButtonWithArrow } from '@components/shared/genericButtonWithArrow/genericButtonWithArrow';
import { ExternalService } from '@app/services/http/external.service';

@Component({
  imports: [
    CommonModule,
    MatIconModule,
    TranslatePipe,
    NgOptimizedImage,
    GenericButtonWithArrow,
  ],
  providers: [ExternalService],
  selector: 'app-why-handyhand',
  templateUrl: './whyHandyhandComponent.html',
  styleUrls: ['./whyHandyhandComponent.scss'],
})
export class WhyHandyhandComponent implements OnInit {
  @Input() isPinployeeVerticalPage = false;
  @Input() isGiftCertificatePage?: boolean = false;
  @Input() isLandingPage?: boolean = false;
  @Input() titleLabel?: string;
  phone = `${environment.cloudFrontImgUrl}/private/lp/phone_create_task.webp`;
  buttonText: string = '';
  currentParams?: string;
  innerWidth: number = 0;

  constructor(
    private router: Router,
    private t: TranslatePipe,
    @Inject(PLATFORM_ID) private platformId: Object,
    public externalService: ExternalService,
  ) {}

  ngOnInit() {
    this.buttonText = this.t.transform('PAGES.LANDING.BNT_TASK4');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }

  buttonAction() {
    if (this.isGiftCertificatePage) {
      return this.router.navigate(['/gavekort/koeb']);
    } else if (this.isPinployeeVerticalPage) {
      return this.openAuthDialog();
    } else {
      return this.externalService.goToCreateTask({
        titleLabel: this.titleLabel,
      });
    }
  }

  /**
   * Method to open the dialog for authenticating user
   */
  openAuthDialog() {
    this.currentParams = this.router.url;
    return this.externalService.goToSignup();
  }
}
