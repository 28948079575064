@if ((viewState$ | async)?.closed === false) {
  <div
    class="activity_container"
    [ngClass]="{
      'has-activities': ((viewState$ | async) ?? {displayedActivities: []}).displayedActivities.length > 0
    }"
  >
    <div class="activity_header">
      <div class="item head_item">
        <p>{{ 'COMPONENTS.ACTIVITY.HEADER' | translate }}</p>
      </div>
      <div class="item close_item" (click)="onClose()">
        <p>
          {{ 'COMPONENTS.ACTIVITY.CLOSE' | translate }}
        </p>
        <mat-icon>close</mat-icon>
      </div>
    </div>
    @if ((viewState$ | async)?.displayedActivities?.length) {
      <div class="activity_content">
        @for (activity of ((viewState$ | async) ?? {displayedActivities: []}).displayedActivities; track getTrackingKey(activity)) {
          <div class="activity_item" @slideInOut>
            <!-- NEW_USER -->
            @if (activity.type === activityType.NEW_USER) {
              <div class="activity_item_content">
                <img
                  [ngSrc]="activity.profileImg ?? placeholderImage"
                  width="40"
                  height="40"
                  loading="lazy"
                  [alt]="'profile picture ' + activity.fullname"
                  (error)="onImgError($event)"
                />
                <div class="three_line_clamp">
                  {{
                    ('COMPONENTS.ACTIVITY.NEW_USER' | translate).replace(
                      '$$fullname$$',
                      activity.fullname
                    )
                  }}
                </div>
              </div>
            }

            <!-- NEW_HH -->
            @if (activity.type === activityType.NEW_HH) {
              <div class="activity_item_content">
                <img
                  [ngSrc]="activity.profileImg ?? placeholderImage"
                  width="40"
                  height="40"
                  [alt]="'profile picture ' + activity.fullname"
                  (error)="onImgError($event)"
                />
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.NEW_HH' | translate).replace(
                        '$$fullname$$',
                        activity.fullname
                      )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToProfile({ userId: activity.id! })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_PROFILE' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_TASK -->
            @if (activity.type === activityType.NEW_TASK) {
              <div class="activity_item_content">
                <mat-icon fontIcon="handyman"></mat-icon>
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.NEW_TASK' | translate).replace(
                        '$$taskname$$',
                        getFormattedTaskname(activity.taskname)
                      )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToTask({
                          title: activity.taskname!,
                          city: activity.city!,
                          taskId: activity.id!
                        })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_TASK' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_BID -->
            @if (activity.type === activityType.NEW_BID) {
              <div class="activity_item_content">
                @if (activity.profileImg) {
                  <img
                    [ngSrc]="activity.profileImg || placeholderImage"
                    width="40"
                    height="40"
                    loading="lazy"
                    [alt]="'profile picture ' + activity.fullname"
                    (error)="onImgError($event)"
                    style="object-fit: cover; border-radius: 50%;"
                  />
                } @else {
                  <mat-icon fontIcon="sell"></mat-icon>
                }

                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.NEW_BID' | translate)
                        .replace('$$fullname$$', activity.fullname)
                        .replace(
                          '$$taskname$$',
                          getFormattedTaskname(activity.taskname)
                        )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToTask({
                          title: activity.taskname!,
                          city: activity.city!,
                          taskId: activity.id!
                        })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_TASK' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- TASK_COMPLETED -->
            @if (activity.type === activityType.TASK_COMPLETED) {
              <div class="activity_item_content">
                <mat-icon fontIcon="done_all"></mat-icon>
                <div class="three_line_clamp">
                  {{
                    ('COMPONENTS.ACTIVITY.TASK_COMPLETED' | translate).replace(
                      '$$taskname$$',
                      getFormattedTaskname(activity.taskname)
                    )
                  }}
                </div>
              </div>
            }

            <!-- TASK_ACCEPTED -->
            @if (activity.type === activityType.TASK_ACCEPTED) {
              <div class="activity_item_content">
                <mat-icon fontIcon="thumb_up"></mat-icon>
                <div class="three_line_clamp">
                  {{
                    ('COMPONENTS.ACTIVITY.TASK_ACCEPTED' | translate).replace(
                      '$$taskname$$',
                      getFormattedTaskname(activity.taskname)
                    )
                  }}
                </div>
              </div>
            }

            <!-- HH_UPDATED -->
            @if (activity.type === activityType.HH_UPDATED) {
              <div class="activity_item_content">
                <img
                  [ngSrc]="activity.profileImg ?? placeholderImage"
                  width="40"
                  height="40"
                  loading="lazy"
                  [alt]="'profile picture ' + activity.fullname"
                  (error)="onImgError($event)"
                />
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.HH_UPDATED' | translate).replace(
                        '$$fullname$$',
                        activity.fullname
                      )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToProfile({ userId: activity.id! })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_PROFILE' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_RATING -->
            @if (activity.type === activityType.NEW_REVIEW) {
              <div class="activity_item_content">
                <img
                  [ngSrc]="activity.profileImg ?? placeholderImage"
                  width="40"
                  height="40"
                  loading="lazy"
                  [alt]="'profile picture ' + activity.fullname"
                  (error)="onImgError($event)"
                />
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.NEW_REVIEW' | translate).replace(
                        '$$fullname$$',
                        activity.fullname
                      )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToProfile({ userId: activity.id! })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_PROFILE' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- HH_VISITED -->
            @if (activity.type === activityType.HH_VISITED) {
              <div class="activity_item_content">
                @if (activity.profileImg) {
                  <img
                    [ngSrc]="activity.profileImg || placeholderImage"
                    width="40"
                    height="40"
                    loading="lazy"
                    [alt]="'profile picture ' + activity.fullname"
                    (error)="onImgError($event)"
                  />
                } @else {
                  <mat-icon>travel_explore</mat-icon>
                }
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.HH_VISITED' | translate)
                        .replace('$$fullname$$', activity.fullname)
                        .replace(
                          '$$taskname$$',
                          getFormattedTaskname(activity.taskname)
                        )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToTask({
                          title: activity.taskname!,
                          city: activity.city!,
                          taskId: activity.id!
                        })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_TASK' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_OFFER_COM -->
            @if (activity.type === activityType.NEW_OFFER_COM) {
              <div class="activity_item_content">
                @if (activity.profileImg) {
                  <img
                    [ngSrc]="activity.profileImg || placeholderImage"
                    width="40"
                    height="40"
                    loading="lazy"
                    [alt]="'profile picture ' + activity.fullname"
                    (error)="onImgError($event)"
                  />
                } @else {
                  <mat-icon>forum</mat-icon>
                }

                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.OFFER_COMMENT' | translate)
                        .replace('$$fullname$$', activity.fullname)
                        .replace(
                          '$$taskname$$',
                          getFormattedTaskname(activity.taskname)
                        )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToTask({
                          title: activity.taskname!,
                          city: activity.city!,
                          taskId: activity.id!
                        })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_TASK' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_TASK_COM -->
            @if (activity.type === activityType.NEW_TASK_COM) {
              <div class="activity_item_content">
                @if (activity.profileImg) {
                  <img
                    [ngSrc]="activity.profileImg || placeholderImage"
                    width="40"
                    height="40"
                    loading="lazy"
                    [alt]="'profile picture ' + activity.fullname"
                    (error)="onImgError($event)"
                  />
                } @else {
                  <mat-icon>forum</mat-icon>
                }
                <div>
                  <div>
                    {{
                      ('COMPONENTS.ACTIVITY.TASK_COMMENT' | translate)
                        .replace('$$fullname$$', activity.fullname)
                        .replace(
                          '$$taskname$$',
                          getFormattedTaskname(activity.taskname)
                        )
                    }}
                  </div>
                  <div>
                    <a
                      (click)="
                        externalService.goToTask({
                          title: activity.taskname!,
                          city: activity.city!,
                          taskId: activity.id!
                        })
                      "
                    >
                      {{ 'COMPONENTS.ACTIVITY.VIEW_TASK' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            }

            <!-- NEW_PAYMENT -->
            @if (activity.type === activityType.NEW_PAYMENT) {
              <div class="activity_item_content">
                <mat-icon>payments</mat-icon>
                <div class="three_line_clamp">
                  {{ 'COMPONENTS.ACTIVITY.NEW_PAYMENT' | translate }}
                </div>
              </div>
            }

            <!-- Date -->
            <div class="activity_item_end">
              <span class="activity_item_end_date">
                {{ activity.date | dateAgo }}
              </span>
            </div>
          </div>
        }
      </div>
    }
  </div>
}
