<section class="graphic_container" id="graphic_container_id">
  <div class="inner_container">
    <div class="wrapper">
      <div class="inner nr1">
        <img
          class="mockup"
          alt="kvinde med telefon"
          width="630"
          height="630"
          ngSrc="/assets/svg/icons/platform-woman.svg"
          loading="lazy"
        />
      </div>

      <div class="inner nr2">
        <h2 class="title">{{ 'PAGES.LANDING.USP_MAIN_TITLE' | translate }}</h2>
        <div>
          @if (!isPinployeeVerticalPage) {
          <div class="usp_wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M24.8986 11.2155C24.8988 14.495 23.7375 17.6687 21.6207 20.1735C19.5038 22.6783 16.5682 24.3525 13.3346 24.8991C10.1009 24.3525 7.16533 22.6783 5.04847 20.1735C2.93162 17.6687 1.77031 14.495 1.77051 11.2155V3.31287C1.77051 2.90394 1.93295 2.51176 2.22211 2.2226C2.51127 1.93344 2.90345 1.771 3.31238 1.771H23.3568C23.5592 1.771 23.7597 1.81088 23.9468 1.88836C24.1339 1.96585 24.3039 2.07942 24.447 2.2226C24.5902 2.36578 24.7038 2.53575 24.7813 2.72282C24.8588 2.90989 24.8986 3.11039 24.8986 3.31287V11.2155Z"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.77051 6.39648H24.8986"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.7313 11.0212L13.1086 16.6439C13.0371 16.7156 12.9522 16.7724 12.8587 16.8111C12.7652 16.8499 12.665 16.8698 12.5638 16.8698C12.4626 16.8698 12.3624 16.8499 12.2689 16.8111C12.1754 16.7724 12.0905 16.7156 12.019 16.6439L10.251 14.877"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="head_wrapper">
              <h3 class="usp_head">
                {{ 'PAGES.LANDING.USP_1_TITLE' | translate }}
              </h3>
              <p>{{ 'PAGES.LANDING.USP_1_DESC' | translate }}</p>
            </div>
          </div>
          } @if (isPinployeeVerticalPage) {
          <div class="usp_wrapper">
            <mat-icon style="font-size: 25px"> calendar_today</mat-icon>
            <div class="head_wrapper">
              <h3 class="usp_head">Fleksibilitet</h3>
              <p>
                Vælg de opgaver, du vil udføre for de mennesker, du er tryg ved
                at arbejde med. Du har kontrol over din egen kalender og kan
                selv finde den balance mellem arbejde og privatliv, der fungerer
                for dig.
              </p>
            </div>
          </div>
          } @else {
          <div class="usp_wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M15.4643 2.45416C15.5142 2.35504 15.5907 2.27174 15.6852 2.21353C15.7797 2.15533 15.8886 2.12451 15.9995 2.12451C16.1105 2.12451 16.2194 2.15533 16.3139 2.21353C16.4084 2.27174 16.4849 2.35504 16.5348 2.45416L17.7793 4.96278C17.8223 5.04878 17.8855 5.12308 17.9634 5.17935C18.0414 5.23562 18.1318 5.27219 18.227 5.28592L21.0193 5.68922C21.1283 5.70454 21.231 5.74992 21.3157 5.82028C21.4005 5.89064 21.4639 5.9832 21.499 6.08759C21.5334 6.19158 21.5377 6.30319 21.5113 6.4095C21.4849 6.5158 21.4289 6.61244 21.3498 6.68823L19.3296 8.65419C19.2614 8.72001 19.2102 8.80147 19.1805 8.89151C19.1509 8.98156 19.1436 9.07747 19.1594 9.17096L19.6379 11.9423C19.656 12.0507 19.6431 12.162 19.6008 12.2635C19.5585 12.3649 19.4884 12.4524 19.3986 12.5158C19.3082 12.5803 19.2017 12.6185 19.0909 12.6262C18.98 12.6338 18.8693 12.6105 18.7709 12.559L16.2783 11.259C16.1924 11.2134 16.0967 11.1896 15.9995 11.1896C15.9024 11.1896 15.8067 11.2134 15.7208 11.259L13.2344 12.559C13.136 12.6105 13.0252 12.6338 12.9144 12.6262C12.8036 12.6185 12.697 12.5803 12.6066 12.5158C12.5168 12.4524 12.4468 12.3649 12.4045 12.2635C12.3621 12.162 12.3493 12.0507 12.3673 11.9423L12.8459 9.17096C12.8616 9.07747 12.8544 8.98156 12.8247 8.89151C12.7951 8.80147 12.7439 8.72001 12.6757 8.65419L10.6493 6.68823C10.5702 6.61244 10.5142 6.5158 10.4878 6.4095C10.4614 6.30319 10.4656 6.19158 10.5001 6.08759C10.5352 5.9832 10.5986 5.89064 10.6834 5.82028C10.7681 5.74992 10.8708 5.70454 10.9798 5.68922L13.7721 5.28592C13.8673 5.27219 13.9577 5.23562 14.0357 5.17935C14.1136 5.12308 14.1768 5.04878 14.2198 4.96278L15.4643 2.45416Z"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.99714 13.6566L7.93276 13.1016C7.84651 13.0567 7.75067 13.0332 7.65341 13.0332C7.55614 13.0332 7.46031 13.0567 7.37405 13.1016L4.88886 14.3966C4.79027 14.4481 4.67942 14.4714 4.56847 14.464C4.45752 14.4566 4.35074 14.4188 4.25986 14.3547C4.17011 14.291 4.10015 14.2033 4.05804 14.1016C4.01593 14 4.00338 13.8885 4.02182 13.78L4.49912 11.0099C4.51525 10.9165 4.50828 10.8206 4.47883 10.7305C4.44938 10.6404 4.39834 10.5589 4.33016 10.4931L2.30254 8.53085C2.22391 8.45476 2.16833 8.35805 2.14215 8.25182C2.11597 8.14558 2.12027 8.03412 2.15453 7.93021C2.18964 7.82582 2.25312 7.73326 2.33785 7.6629C2.42259 7.59254 2.52524 7.54716 2.63431 7.53184L5.42537 7.12854C5.52075 7.11499 5.61142 7.07851 5.68961 7.02223C5.76781 6.96595 5.83118 6.89155 5.8743 6.8054L7.11998 4.29677C7.16976 4.19754 7.24622 4.11415 7.34078 4.05598C7.43533 3.99781 7.54424 3.96716 7.65526 3.96747"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.0029 13.6566L24.0673 13.1004C24.1536 13.0554 24.2494 13.032 24.3467 13.032C24.4439 13.032 24.5398 13.0554 24.626 13.1004L27.1112 14.3966C27.2098 14.4481 27.3206 14.4714 27.4316 14.464C27.5425 14.4566 27.6493 14.4188 27.7402 14.3547C27.83 14.291 27.8999 14.2033 27.942 14.1016C27.9841 14 27.9967 13.8885 27.9782 13.78L27.5009 11.0099C27.4848 10.9165 27.4918 10.8206 27.5212 10.7305C27.5507 10.6404 27.6017 10.5589 27.6699 10.4931L29.6975 8.52592C29.7762 8.44983 29.8317 8.35312 29.8579 8.24688C29.8841 8.14065 29.8798 8.02919 29.8455 7.92528C29.8104 7.82088 29.7469 7.72833 29.6622 7.65797C29.5775 7.58761 29.4748 7.54223 29.3658 7.52691L26.5747 7.1236C26.4793 7.11006 26.3886 7.07358 26.3105 7.0173C26.2323 6.96102 26.1689 6.88662 26.1258 6.80047L24.8801 4.29677C24.8303 4.19754 24.7538 4.11415 24.6593 4.05598C24.5647 3.99781 24.4558 3.96716 24.3448 3.96747"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.2003 29.8665C21.4273 28.9829 20.4742 28.2748 19.4051 27.7896C18.336 27.3045 17.1756 27.0535 16.0015 27.0535C14.8275 27.0535 13.667 27.3045 12.5979 27.7896C11.5288 28.2748 10.5758 28.9829 9.80273 29.8665"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.9121 20.1144C10.9121 21.4637 11.4481 22.7577 12.4022 23.7118C13.3563 24.666 14.6504 25.202 15.9997 25.202C17.349 25.202 18.643 24.666 19.5971 23.7118C20.5512 22.7577 21.0872 21.4637 21.0872 20.1144C21.0872 18.7651 20.5512 17.4711 19.5971 16.517C18.643 15.5629 17.349 15.0269 15.9997 15.0269C14.6504 15.0269 13.3563 15.5629 12.4022 16.517C11.4481 17.4711 10.9121 18.7651 10.9121 20.1144Z"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="head_wrapper">
              <h3 class="usp_head">
                {{ 'PAGES.LANDING.USP_2_TITLE' | translate }}
              </h3>
              <p>{{ 'PAGES.LANDING.USP_2_DESC' | translate }}</p>
            </div>
          </div>
          } @if (isPinployeeVerticalPage) {
          <div class="usp_wrapper">
            <mat-icon style="font-size: 25px">payment</mat-icon>
            <div class="head_wrapper">
              <h3 class="usp_head">Hurtig og sikker betaling</h3>
              <p>
                Vi sikrer din betaling allerede inden du starter opgaven, så du
                kan være sikker på, at pengene er der, når opgaven er fuldført.
              </p>
            </div>
          </div>
          } @else {
          <div class="usp_wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M7.42916 13.1424L6.46592 10.1491C6.31569 9.68229 6.35692 9.17496 6.58055 8.73856C6.80418 8.30217 7.19192 7.97241 7.65856 7.82174L24.8095 2.29759C25.2764 2.14741 25.7839 2.18881 26.2203 2.41269C26.6568 2.63657 26.9864 3.02459 27.1368 3.49147L29.7021 11.4539C29.7768 11.6852 29.805 11.9289 29.7853 12.1711C29.7657 12.4134 29.6984 12.6493 29.5876 12.8655C29.4767 13.0818 29.3244 13.2741 29.1391 13.4314C28.954 13.5887 28.7396 13.7081 28.5083 13.7825L22.5574 15.6991"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5572 24.2425V27.4134C22.5562 27.7268 22.4936 28.037 22.3727 28.3261C22.252 28.6153 22.0754 28.8779 21.853 29.0988C21.6307 29.3197 21.367 29.4947 21.0772 29.6137C20.7872 29.7327 20.4767 29.7935 20.1633 29.7925H4.60096C4.28755 29.7935 3.97702 29.7327 3.68711 29.6137C3.39719 29.4947 3.13355 29.3197 2.91125 29.0988C2.68896 28.8779 2.51235 28.6153 2.39152 28.3261C2.27069 28.037 2.208 27.7268 2.20703 27.4134V15.5202C2.208 15.2068 2.2707 14.8968 2.39155 14.6077C2.5124 14.3186 2.68901 14.0561 2.91134 13.8354C3.13365 13.6145 3.39729 13.4396 3.6872 13.3207C3.97711 13.2018 4.28762 13.1412 4.60096 13.1423H20.1633C20.4767 13.1412 20.7871 13.2018 21.0771 13.3207C21.367 13.4396 21.6306 13.6145 21.853 13.8354C22.0752 14.0561 22.2518 14.3186 22.3727 14.6077C22.4936 14.8968 22.5562 15.2068 22.5572 15.5202V18.6924"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.83301 13.1609V29.7926"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.6447 18.6924C17.9375 18.7219 17.2707 19.0304 16.7906 19.5507C16.3104 20.0708 16.056 20.7601 16.083 21.4674C16.056 22.1747 16.3104 22.8641 16.7906 23.3842C17.2707 23.9043 17.9375 24.213 18.6447 24.2424H23.1279C23.4814 24.2274 23.8145 24.0729 24.0544 23.8129C24.2943 23.5529 24.4215 23.2084 24.4081 22.8549V20.0799C24.4218 19.7263 24.2947 19.3816 24.0547 19.1216C23.8147 18.8615 23.4815 18.7071 23.1279 18.6924H18.6447Z"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.2106 21.9299C20.9551 21.9299 20.748 21.7228 20.748 21.4674C20.748 21.212 20.9551 21.0049 21.2106 21.0049"
                stroke="#1A5681"
                stroke-width="2"
              />
              <path
                d="M21.2109 21.93C21.4664 21.93 21.6734 21.7229 21.6734 21.4674C21.6734 21.212 21.4664 21.0049 21.2109 21.0049"
                stroke="#1A5681"
                stroke-width="2"
              />
              <path
                d="M16.2825 10.45C16.4508 10.118 16.7155 9.84458 17.0417 9.66539C17.3679 9.4862 17.7407 9.40958 18.1112 9.44555C18.4817 9.48151 18.8327 9.62839 19.1183 9.86699C19.4039 10.1056 19.611 10.4248 19.7124 10.783"
                stroke="#1A5681"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="head_wrapper">
              <h3 class="usp_head">
                {{ 'PAGES.LANDING.USP_3_TITLE' | translate }}
              </h3>
              <p>
                {{ 'PAGES.LANDING.USP_3_DESC' | translate }}
                <span
                  ><a href="skadegodtgoerelse"
                    >{{ 'PAGES.LANDING.USP_3_LINK' | translate }}</a
                  ></span
                >
              </p>
            </div>
          </div>
          } @if (isPinployeeVerticalPage) {
          <div class="usp_wrapper">
            <mat-icon style="font-size: 25px"> tablet_android</mat-icon>
            <div class="head_wrapper">
              <h3 class="usp_head">Nemt at bruge</h3>
              <p>
                Du kan begynde at søge job i nærheden af dig med det samme. Du
                kan filtrere efter placering og kategorier og få besked, når der
                dukker op opgaver, du er interesseret i nærheden af dig.
              </p>
            </div>
          </div>
          }
        </div>
        <div class="button_div">
          <app-generic-button-with-arrow
            [label]="'PAGES.LANDING.TO_BUTTON' | translate"
            (click)="buttonAction()"
          ></app-generic-button-with-arrow>
        </div>
      </div>
    </div>
  </div>
</section>
