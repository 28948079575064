import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  NgZone,
  ApplicationRef,
} from '@angular/core';
import { ActivityModel, ActivityType } from '@app/models/activity.model';
import { ExternalService } from '@app/services/http/external.service';
import { MiscService } from '@app/services/http/misc.service';
import {
  Observable,
  first,
  Subject,
  BehaviorSubject,
  timer,
  retry,
} from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/handyhand/environment.dk';

import {
  CommonModule,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { TranslatePipe } from '@app/pipes/translate.pipe';
import { DateAgoPipe } from '@app/pipes/date-ago-pipe';
import { takeUntil } from 'rxjs/operators';

@Component({
  imports: [
    CommonModule,
    DateAgoPipe,
    MatIconModule,
    NgOptimizedImage,
    TranslatePipe,
  ],
  providers: [ExternalService, MiscService, TranslatePipe],
  host: { ngSkipHydration: 'true' },
  selector: 'app-platform-activity',
  templateUrl: './platform-activity.component.html',
  styleUrls: ['./platform-activity.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(0%)', opacity: 0 }),
        animate(
          '500ms ease-out',
          style({ transform: 'translateY(0%)', opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        animate(
          '500ms ease-in',
          style({ transform: 'translateY(100%)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class PlatformActivityComponent implements OnInit, OnDestroy {
  private isBrowser: boolean;
  private destroy$ = new Subject<void>();
  private retryTimeout: any = null; // Add property to store timeout reference
  intervalTime: number = 0; // Defines the interval time (in milliseconds)
  intervalMax = 6000; // Defines the maximum interval time (in milliseconds)
  intervalMin = 2000; // Defines the minimum interval time (in milliseconds)
  closed = false;

  // Defines the type of activity
  activityType = ActivityType;
  // Holds the interval instance
  interval: any;
  // Observables for activities
  activities$: Observable<ActivityModel[]> = new Observable<ActivityModel[]>();
  // List of activities
  activities: ActivityModel[] = [];
  // Activities currently being displayed
  displayedActivities: ActivityModel[] = [];
  // Keeps track of already displayed activities
  alreadyDisplayedActivities: Set<string> = new Set<string>();

  public readonly placeholderImage = '/assets/svg/logo.svg';

  // Add a counter for unique keys
  private activityCounter = 0;

  // Update the viewState$ observable initialization
  viewState$ = new BehaviorSubject<{
    closed: boolean;
    displayedActivities: ActivityModel[];
  }>({
    closed: false,
    displayedActivities: [],
  });

  // Constructor, where the miscService is injected
  constructor(
    private miscService: MiscService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public externalService: ExternalService,
    private ngZone: NgZone,
    private applicationRef: ApplicationRef,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  // Generate a UUID without using crypto.randomUUID()
  private generateUUID(): string {
    // Simple UUID generator fallback
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  // Lifecycle hook for component initialization
  ngOnInit() {
    if (this.isBrowser) {
      try {
        const hasBeenClosed = localStorage.getItem(
          'ng2-webstorage|userActivityClosed',
        );
        if (hasBeenClosed) {
          this.viewState$.next({
            closed: true,
            displayedActivities: [],
          });
          return;
        }
      } catch (e) {
        console.error('Error accessing localStorage:', e);
      }

      this.getLastActivities();
      this.setupInterval();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    // Clear the retry timeout to prevent memory leaks
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Sets up the interval to fetch activities
  setupInterval() {
    if (!this.isBrowser) return;

    if (this.interval) clearInterval(this.interval);

    this.intervalTime =
      Math.floor(Math.random() * (this.intervalMax - this.intervalMin + 1)) +
      this.intervalMin;

    // Run interval outside Angular zone
    this.ngZone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        // Run interval handler inside Angular zone when needed
        this.ngZone.run(() => {
          this.intervalHandler();
        });
      }, this.intervalTime);
    });
  }

  // Handler for the interval, fetches activities or displays an activity
  intervalHandler() {
    if (!this.activities || this.activities.length === 0) {
      this.getLastActivities(); // Uncomment this line to fetch activities when none exist
      return;
    }
    this.displayFirst(); // Displays the first activity from the list
  }

  getLastActivities() {
    console.log('getLastActivities called');
    this.miscService
      .getLastActivities()
      .pipe(
        first(),
        takeUntil(this.destroy$),
        // Add retry logic for network issues including SSL errors
        retry({
          count: 2,
          delay: (error, retryCount) => {
            console.warn(
              `Activity fetch attempt ${retryCount} failed:`,
              error?.message || 'Unknown error',
            );
            return timer(2000); // 2 second delay between retries
          },
        }),
      )
      .subscribe({
        next: (activities) => {
          this.activities = activities;
          // Display the first three activities
          for (let i = 0; i < 3 && this.activities.length > 0; i++) {
            this.displayFirst();
          }
        },
        error: () => {
          // Use empty activities array to allow component to continue functioning
          this.activities = [];
          // Try again later with a longer delay
          if (this.isBrowser) {
            // Clear any existing timeout before setting a new one
            if (this.retryTimeout) {
              clearTimeout(this.retryTimeout);
            }
            // Store the timeout reference so it can be cleared later
            this.retryTimeout = setTimeout(
              () => this.getLastActivities(),
              30000,
            ); // Try again in 30 seconds
          }
        },
      });
  }

  // Modify displayFirst to better handle duplicates
  displayFirst() {
    if (this.activities && this.activities.length > 0) {
      const activity = { ...this.activities[0] };
      this.activities = this.activities.slice(1);

      // Generate a unique timestamp if not present
      if (!activity.date) {
        activity.date = new Date();
      }

      // Create a unique ID for display purposes if not present
      if (!activity.id) {
        // Use our safe UUID generator instead of crypto.randomUUID()
        activity.id = this.generateUUID();
      }

      // Generate unique tracking ID immediately
      activity.trackingId = `${activity.id}_${
        activity.type
      }_${activity.date.getTime()}_${this.activityCounter++}`;

      const actHash = this.hashActivity(activity);
      if (this.alreadyDisplayedActivities.has(actHash)) {
        this.displayFirst();
        return;
      }

      // Update the viewState$ BehaviorSubject
      const currentState = this.viewState$.getValue();
      const newDisplayedActivities = [
        activity,
        ...currentState.displayedActivities,
      ];

      // Limit displayed activities to prevent memory issues
      const limitedActivities = newDisplayedActivities.slice(0, 10);
      this.viewState$.next({
        ...currentState,
        displayedActivities: limitedActivities,
      });

      this.alreadyDisplayedActivities.add(actHash);
    }
  }

  onImgError(event: Event) {
    if (this.isBrowser && event.target instanceof HTMLImageElement) {
      event.target.src = `${environment.cloudFrontImgUrl}/private/compressed/Group.svg`;
    }
  }

  // Update hashActivity to use more properties for uniqueness
  hashActivity(activity: ActivityModel): string {
    return `${activity.id}_${activity.type}_${activity.date?.getTime()}_${
      activity.fullname
    }_${activity.taskname}`;
  }

  getFormattedTaskname(taskName: string | undefined): string {
    if (!taskName) return '';
    return taskName?.length > 20
      ? taskName?.substring(0, 17) + '...'
      : taskName;
  }

  onClose() {
    if (this.isBrowser) {
      clearInterval(this.interval);
      this.activities = [];
      this.viewState$.next({
        closed: true,
        displayedActivities: [],
      });
      localStorage.setItem('ng2-webstorage|userActivityClosed', 'true');
    }
  }

  // Update getTrackingKey to always return the trackingId
  getTrackingKey(activity: ActivityModel): string {
    return activity.trackingId!;
  }

  protected readonly environment = environment;
}
