import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Pipe({
  name: 'dateAgo',
  pure: true,
  standalone: true,
})
export class DateAgoPipe implements PipeTransform {
  seconds: number = 0;
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  transform(value: any, args?: any): any {
    let lang = 'dk'; // dk | en
    if (value) {
      this.seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (this.isBrowser) {
        const storedLang = localStorage.getItem('ngx-webstorage|pinploy_lang');
        try {
          lang = storedLang ? JSON.parse(storedLang)?.value ?? 'dk' : 'dk';
        } catch (e) {
          lang = 'dk';
        }
      } else {
        lang = 'dk';
      }

      // Select language to translate
      if (lang === 'dk') {
        value = this.danishLangConfig();
      } else {
        value = this.englishLangConfig();
      }
    }

    return value;
  }

  englishLangConfig(): string | undefined | void {
    if (this.seconds < 29) {
      // less than 30 seconds ago
      return 'Just now';
    }

    let counter;
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    for (const i in intervals) {
      // @ts-ignore
      counter = Math.floor(this.seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return `${counter} ${i} ago`; // singular (1 day ago)
        }
        if (i === 'day') {
          return `${counter} ${i}s ago`; // plural (2 days ago)
        }
        if (i === 'week' || i === 'time') {
          return `${counter} ${i}s ago`; // plural (2 days ago)
        }
        if (i === 'month' || i === 'second') {
          return `${counter} ${i}s ago`; // plural (2 days ago)
        }
        if (i === 'minute') {
          return `${counter} ${i}s ago`; // plural (2 days ago)
        }
        return `${counter} ${i} ago`; // plural (2 days ago)
      }
    }
  }

  danishLangConfig(): string | undefined | void {
    // less than 30 seconds ago
    if (this.seconds < 29) {
      return 'Lige nu..';
    }
    const intervals = {
      år: 31536000,
      måned: 2592000,
      uge: 604800,
      dag: 86400,
      time: 3600,
      minut: 60,
      sekund: 1,
    };

    let counter;
    for (const i in intervals) {
      // @ts-ignore
      counter = Math.floor(this.seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return `${counter} ${i} siden`; // singular (1 day ago)
        }
        if (i === 'dag') {
          return `${counter} ${i}e siden`; // plural (2 days ago)
        }
        if (i === 'uge' || i === 'time') {
          return `${counter} ${i}r siden`; // plural (2 days ago)
        }
        if (i === 'måned' || i === 'sekund') {
          return `${counter} ${i}er siden`; // plural (2 days ago)
        }
        if (i === 'minut') {
          return `${counter} ${i}ter siden`; // plural (2 days ago)
        }
        return `${counter} ${i} siden`; // plural (2 days ago)
      }
    }
  }
}
